import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  GHLResponse,
  Identity,
  LoginCommand,
  Profile,
  RoleAuthenticationEntity,
  ersConstants,
} from '@rcs/utils';
import { isPlatformBrowser } from '@angular/common';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, map, of } from 'rxjs';
import { isNullOrUndefined } from '@slck/utils';

@Injectable({
  providedIn: 'root',
})
export class ErsIdentityService {
  private readonly _authUrl = `${environment.apiUrl.identity}`;
  private readonly _userUrl = `${environment.apiUrl.identity}`;
  private readonly _accessUrl = `${environment.apiUrl.access}`;

  private _jwt = new JwtHelperService();
  constructor(
    private _http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  storeItemToLs = (key: string, value: string) => {
    switch (key) {
      case ersConstants.ERS_JWT_TOKEN:
        if (isPlatformBrowser(this.platformId)) {
          localStorage.setItem(ersConstants.ERS_JWT_TOKEN, value);
        }
        break;
      case ersConstants.BOT_TOKEN:
        if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem(ersConstants.BOT_TOKEN, value);
        }
          break;
      default:
        if (isPlatformBrowser(this.platformId)) {
          localStorage.setItem(key, value);
        }
        break;
    }
    return of(true);
  };

  retriveitemFromLs = (key: string): string | null =>
    isPlatformBrowser(this.platformId) ? localStorage.getItem(key) : ``;

  retriveitemFromLsAsObservable = (key: string): Observable<string | null> =>
    isPlatformBrowser(this.platformId) ? of(localStorage.getItem(key)) : of(``);

  removeItemFromLs = (key: string): void | null =>
    isPlatformBrowser(this.platformId) ? localStorage.removeItem(key) : null;

  isTokenExpired = () => {
    const token = this.retriveitemFromLs(ersConstants.ERS_JWT_TOKEN);

    return token
      ? token.length > 0
        ? !this._jwt.isTokenExpired(token)
        : false
      : false;
  };

  toObservableJwtToken(): Observable<string> {
    const token = this.retriveitemFromLs(ersConstants.ERS_JWT_TOKEN);
    return of(token ? token : '');
  }

  logOutRequest(): void {
    this.removeItemFromLs(ersConstants.ERS_JWT_TOKEN);
    this.removeItemFromLs(ersConstants.SELECTED_TENANT);
    this.removeItemFromLs(ersConstants.AUTHENTICATION_PROVIDERS_TYPE);
    this.removeItemFromLs(ersConstants.REMEMBER_ME);
    this.removeItemFromLs(ersConstants.GHL_TOKEN);
    this.removeItemFromLs(ersConstants.GHL_USERID);
    this.removeItemFromLs(ersConstants.ROLE_BASED_AUTHENTICATION);
  }

  doErsLogin = (query: LoginCommand): Observable<Identity> =>
    this._http
      .post(`${this._authUrl}Authentication/login`, query)
      .pipe(map((res: any) => (!isNullOrUndefined(res) ? res : {})));

  fetchProfilezByLoginId = (
    loginid: string,
    tenantid: string
  ): Observable<Profile> =>
    this._http
      .get(`${this._userUrl}User/getuserbylogintenantid/${loginid}/${tenantid}`)
      .pipe(map((res) => res as Profile));

  /* GHL */
  getGHLAccessToken = (code: string): Observable<GHLResponse> => {
    const data = {
      client_id: environment.gHLConfig.client_id,
      client_secret: environment.gHLConfig.client_secret,
      grant_type: environment.gHLConfig.grant_type,
      code: code,
      user_type: environment.gHLConfig.user_type,
      redirect_uri: environment.gHLConfig.redirect_uri,
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const body = this.urlEncode(data);

    return this._http
      .post(environment.gHLEndpoints.getcAccessToken, body, {
        headers,
      })
      .pipe(
        map((res: any) =>
          !isNullOrUndefined(res)
            ? res
            : {
                access_token: '',
              }
        )
      );
  };

  private urlEncode(data: any): string {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      )
      .join('&');
  }

  fetchGHLUserInfo = (token: string, userId: string): Observable<string> => {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + token,
      Version: environment.gHLConfig.version,
    });
    return this._http
      .get(`${environment.gHLEndpoints.fetchUser}${userId}`, {
        headers,
      })
      .pipe(map((res: any) => (!isNullOrUndefined(res) ? res.email : '')));
  };

  fetchTokenFromEmail = (email: string): Observable<Identity> =>
    this._http
      .post(`${this._authUrl}Authentication/gettokenfromemail`, { email })
      .pipe(map((res: any) => (!isNullOrUndefined(res) ? res : {})));
  /* End GHL */

  /* Role Authentication */
  fetchRoleAuthInfo = (
    userId: string,
    tenantId: string
  ): Observable<RoleAuthenticationEntity[]> => {
    return this._http
      .get(
        `${this._accessUrl}Access/getroleauthbyuserIdandtenantId/${userId}/${tenantId}`
      )
      .pipe(map((res: any) => (!isNullOrUndefined(res) ? res : [])));
  };
  /* End Role Authentication */
}
